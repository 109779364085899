import React, { useRef } from "react"
import emailjs from "@emailjs/browser"
import Alert from 'react-popup-alert'

export default function Contact() {
    const [alert, setAlert] = React.useState({
        type: 'error',
        text: 'Messesage Sent',
        show: false
      })
      
      function onCloseAlert() {
        setAlert({
          type: '',
          text: '',
          show: false
        })
      }
    
      function onShowAlert(type) {
        
          setAlert({
            type: type,
            text: 'Message sent!',
            show: true
          })
        
    }
    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_yk4hrm9', 'template_f59g8jd', form.current, 'xmfMs81-iGWlTwVbS')
        .then((result) => {
            console.log(result.text);
            
        }, (error) => {
            console.log(error.text);
        });
    }

    return (
        <div>
            <div className="contact--section" id="contact">

                <form className="contact--form" ref={form} onSubmit={sendEmail}>
                    <h1 className="white--text">Contact</h1>
                    <div className="form--elements">
                        <input
                            type="text"
                            placeholder="Name"
                            name="user_name"
                        />
                    </div>
                    <div className="form--elements">
                        <input
                            type="email"
                            placeholder="Your Email"
                            name="user_email"
                        />
                    </div>
                    
                    <div className="form--elements">
                        <textarea 
                            placeholder="Message"
                            name="message"
                            className="email--message"
                        />
                    </div>
                    <div className="form--elements">
                        <input type="submit" className="submit--btn" value="Send" onClick={() => onShowAlert('success')}/>
                    </div>
                    <div className='alert--box'>
        <Alert
        header={''}
        btnText={'Close'}
        text={alert.text}
        type={alert.type}
        show={alert.show}
        onClosePress={onCloseAlert}
        pressCloseOnOutsideClick={true}
        showBorderBottom={false}
        alertStyles={{
          
          backgroundColor: 'rgb(26, 24, 24)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "center",
          alignItems: "center",}}
        headerStyles={{}}
        textStyles={{ color: 'white'}}
        buttonStyles={{  
          backgroundColor: 'rgb(26, 24, 24)',
          color: 'white',
          }}
      />
        </div>
                </form>

                <div className="contact--links">
                    <div>
                        <a href="https://www.linkedin.com/in/erick1op6z/">
                            <i class="fa fa-linkedin-square contact--icon--font" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div>
                        <a href="https://github.com/LordLopez666">
                            <i class="fa fa-github contact--icon--font" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div>
                        <a href="https://codepen.io/LordLopez">
                            <i class="fa fa-codepen contact--icon--font" aria-hidden="true"></i>
                        </a>
                    </div>
                    <div>
                        <a href="https://twitter.com/6LordLopez9">
                            <i class="fa fa-twitter-square contact--icon--font" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>  
                <p className="credits">Built and designed by Erick Lopez</p>     
            </div>
        </div>
    )
}