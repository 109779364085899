import React from "react";
import myImg from "../images/portfolio-photo.jpg"


export default function About() {
    return (
        <div className="about--section" id="about">
            <div className="about--left">
                <h2 className="white--text about--titles">About me</h2>
                <p className="white--text">Hi! My name is Erick and I strive to make simple, 
            yet profesional websites. My interest in web developement 
                started in 2019 while attending Utah valley University. 
                </p>
                
                <p className="white--text">I really do like computer programming so much. I always enjoy learning 
                about new things, for example when I first understood how React.useEffect works and how it can interact with
                React.useState, 
                my mind was blown and after I had the best two days ever. 
                I also always look forward to learning something new, I want to gobble it all up. I'm currently taking 
                an advanced React course which is very fun. I'm also learning Python and Java which seem to come easier
                after having learned JavaScript.</p>
                <p className="white--text">In the future, I want to learn Vue.js as well as Angular. I built a small application
                using Vue.js and I had a lot of fun doing so. I can't wait to have the same feeling 
                of amazement I've had so many times before when learning something new. </p>
                <p className="white--text">Please feel free, if not encourged to, send me an email at the bottom of the page.</p>
                
            </div>
            <div className="fun--facts--box">
                <div className="photo--container">
                    <img src={myImg}/>
                </div>
                <h2 className="white--text">Fun facts</h2>
                <div className="fun--facts">
                <ul className="white--text">
                    <li>I play chess.</li>
                    <li>I can do a kickflip.</li>
                    <li>I like to play guitar.</li>
                    <li>I dream about writting JavaScript and React code all the time.</li>
                </ul>
                </div>
            </div>
        </div>
    )
}
